<template>
  <div style="min-height: calc(100vh - 75px);">
    <div class="app-modal app-modal--produtoDetalhes">
      <div class="container">
        <div class="app-modal--header">
          <div class="app-modal--action" @click="close()">
            <b-icon-chevron-left></b-icon-chevron-left>
          </div>
          <h2>Avaliação</h2>
        </div>
        <div class="app-modal--conteudo">

          <div class="restaurante-data">
            <p class="mb-0 font-weight-500">Avalie seu pedido</p>
            <p class="text-cor-cinza font-0x mb-0">05/11/2020 - Bliss Burger</p>
            <hr/>
          </div>
          <div class="lista-produtos">
            <div class="item d-flex justify-content-between">
              <p class="mb-0">1x Monte sua massa</p>
              <p class="mb-0">R$ 24,99</p>
            </div>
            <hr/>
          </div>
          <div class="restaurante-avalie">
            <p class="mb-0 font-weight-500">O que você achou da comida?</p>
            <p class="text-cor-cinza font-0x mb-0">Escolha de 1 a 5 estrelas para classificar.</p>
            <b-icon-star-fill class="text-cor-cinza"></b-icon-star-fill>
          </div>
          <div class="restaurante-entrega">
            <p class="mb-0 font-weight-500">Você gostou da entrega?</p>
            <p class="text-cor-cinza font-0x mb-0">Conte-nos se gostou ou não.</p>
            <div class="restaurante-entrega--avalie">
              <div class="app-produto-comp--item">
                <div class="titulo">Sim</div>
                <div class="check"></div>
              </div>
              <div class="app-produto-comp--item">
                <div class="titulo">Não</div>
                <div class="check"></div>
              </div>
              <div class="btn btn-cor-primaria w-100">Avaliar</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvaliacaoPedido"
}
</script>

<style scoped>

</style>