<template>
  <div v-if="loja" id="pagina-estabelecimento" class="app-modal app-modal--carrinho app-page-full"
       style="overflow-y: auto">

    <div class="app-modal--header">
      <div class="container">
        <div class="app-modal--action text-white" @click="close">
          <b-icon-chevron-left></b-icon-chevron-left>
        </div>
      </div>
    </div>

    <div class="app-modal--conteudo app-modal--rounded position-relative">

      <div class="app-modal--roundedConteudo" v-if="loja">

        <div class="app-modal--est-header d-flex justify-content-center align-items-center flex-column">
          <img :src="loja.img" alt="" class="img-fluid app-modal--est-img flex-fill">
          <h2 class="my-2">{{ loja.nomeFantasia }}</h2>
        </div>

        <div class="app-modal--est-misc text-center mb-3">
          <div class="container">
            <span class="badge badge-secondary-outline mx-1">
              <b-icon-star-fill></b-icon-star-fill> 4.2
            </span>
            <span class="badge badge-secondary-outline mx-1">
              <b-icon-clock-history></b-icon-clock-history> 40 - 55 min
            </span>
            <span class="badge badge-secondary-outline mx-1">
              <b-icon-tags></b-icon-tags> Lanches
            </span>
          </div>
        </div>

        <div class="app-modal--est-itens destaques" v-if="destaques.length">

          <hr/>

          <div class="container">

            <div class="secao">
              <h4 class="font-1x mb-3">Destaques</h4>
            </div>

            <div class="cards">
              <carousel :items="2" :autoplay="false" :nav="false" :dots="false">
                <router-link :to="`/produto/${v.token}`" v-for="(v, index) in destaques"
                             v-bind:key="`destaque-${index}`">
                  <div class="pb-3">
                    <div class="card-est-item vertical mr-3">
                      <div class="card-est-item-img">
                        <img :src="v.img" class="img-fluid" alt="">
                      </div>
                      <div class="card-est-item--info">
                        <div class="card-est-item--txt">
                          <h4>{{ v.title }}</h4>
                          <p>{{ v.contem.map(v => v.title).join(', ') }}</p>
                        </div>
                        <div class="card-est-item--precos d-flex">
                          <div class="valor mx-1">R$ {{ v.valor|real }}</div>
                          <div class="valor-sem-desconto mx-1" v-if="v.valorde > 0">R$ {{ v.valorde|real }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </router-link>
              </carousel>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="categorias" id="scroll-categorias">
      <div v-for="cat in categorias" :key="`categorias-item-${cat.id}`" class="categorias-item"
           :class="{active: cat.active}" @click="setCategoria(cat)" :id="`cat${cat.id}`">

        <h4 class="font-1x m-0">
          {{ cat.title }}
        </h4>

      </div>
    </div>

    <div class="app-modal--est-itens outros categoria-produtos" v-for="cat in categorias" :class="`categoria-${cat.id}`"
         :key="`categoria-${cat.id}`">

      <hr class="mt-0"/>

      <div class="container">
        <div class="cards">
          <div v-for="produto in produtos.filter(v => v.categoria === cat.id)" :key="`produtos-${produto.token}`">
            <router-link :to="`/produto/${loja.token}/${produto.token}`">
              <div class="card-est-item d-flex mb-3">
                <div class="card-est-item-img" style="width: 90px; height: 90px;">
                  <img :src="produto.img" class="img-fluid" alt="">
                </div>
                <div class="card-est-item--info">
                  <div class="card-est-item--txt">
                    <h4>{{ produto.title }}</h4>
                    <p v-if="produto.contem.length">{{ produto.contem.join(', ') }}</p>
                  </div>
                  <div class="card-est-item--precos d-flex">
                    <div class="valor mx-1">R$ {{ produto.valor|real }}</div>
                    <div class="valor-sem-desconto mx-1" v-if="produto.valorde > 0">R$ {{ produto.valorde|real }}</div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer :loja="loja"/>

  </div>
</template>

<script>
import carousel from 'vue-owl-carousel2';
import apiHttp from "../apiHttp";
import Footer from "../component/Footer";
import {getLoja, getProdutos} from "../store";

export default {
  name: "EstabelecimentoPage",
  components: {Footer, carousel},
  data() {
    return {
      page: null,
      loja: null,
      elCategorias: null,
      categorias: [],
      destaques: [],
      produtos: [],
    }
  },
  async created() {
    apiHttp.defaults.headers['loja-token'] = this.$route.params.token;
    await this.loadLoja();
    await this.loadProdutos();
  },
  mounted() {
    setTimeout(() => {

      this.page = document.getElementById('pagina-estabelecimento');
      this.elCategorias = document.getElementById('scroll-categorias');

      this.page.onscroll = () => {

        let encontrou = false;
        const scrollValue = this.page.scrollTop + this.elCategorias.offsetHeight;

        this.categorias.forEach((cat) => {
          if (!encontrou) {

            const el = document.getElementsByClassName('categoria-' + cat.id)[0];
            const botao = document.getElementById('cat' + cat.id);

            if (el) {

              if (scrollValue >= el.offsetTop && scrollValue <= el.offsetTop + el.offsetHeight) {

                this.categorias.forEach(v => v.active = false);

                cat.active = true;

                this.elCategorias.scrollLeft = botao.offsetLeft;

                encontrou = el;

                this.$forceUpdate();

              }
            }

          }
        });

      }

    }, 300);
  },
  methods: {
    async loadLoja() {

      this.loja = await getLoja(this.$route.params.token);

      document.documentElement.style.setProperty('--estPage--bg', `url('${this.loja.img}')`);

    },
    close() {

      this.$router.push('/');

    },
    async loadProdutos() {

      console.log(this.loja);

      const {produtos, categorias} = await getProdutos(this.loja);

      this.produtos = produtos;
      this.destaques = produtos.filter(v => v.destaque);

      this.categorias = categorias.map((v, index) => {
        v.active = !index;
        return v;
      });

    },
    setCategoria(cat) {

      this.categorias.forEach(v => v.active = false);

      document.getElementsByClassName('categoria-' + cat.id).forEach(el => {

        this.page.scrollTop = el.offsetTop - this.elCategorias.offsetHeight;

      });

      cat.active = true;

      this.$forceUpdate();

    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change')
    },
  },
}
</script>

<style lang="scss">

@import '@/assets/scss/card-item';

:root {
  --estPage--bg: url('/estBg.jpg');
}

.owl-carousel .owl-stage-outer {
  overflow: visible !important;
}

.categorias {
  box-shadow: 0 5px 5px rgba(black, 0.03);
  position: sticky;
  background-color: white;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  z-index: 99;
  overflow: auto;

  .categorias-item {
    display: block;
    padding: 15px 15px;
    margin: 0;
    border-bottom: 3px solid transparent;
    white-space: nowrap;

    &.active {
      border-bottom-color: black;
    }
  }
}

.app-modal--est-header {
  h2 {
    font-size: 1.6em;
  }
}

.app-modal--est-misc {
  .badge {
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    padding: 7px 15px;
    font-weight: 400;
    font-size: .8em;

    svg {
      color: var(--cor-primaria-normal);
    }
  }
}

.app-modal--est-img {
  height: 100px !important;
  width: 100px !important;
  border: 5px solid #FFF;
  border-radius: 50px;
  margin-top: -50px;
}

</style>