<template>
  <div style="min-height: calc(100vh - 75px);">
    <div v-if="produto && loja">
      <div class="app-modal app-modal--produtoDetalhes">
        <div class="container">
          <div class="app-modal--header">
            <div class="app-modal--action" @click="close">
              <b-icon-chevron-left></b-icon-chevron-left>
            </div>
            <h2>{{ loja.nomeFantasia }}</h2>
          </div>
          <div class="app-modal--conteudo">

            <div class="informacoes-produto my-3">
              <div class="imagem">
                <img :src="produto.img" alt="">
              </div>
              <div class="info">
                <b>{{ produto.title }}</b>
                <p>{{ produto.contem.join(', ') }}</p>
                <b v-if="valor > 0">A partir de R$ {{ valor|real }}</b>
              </div>
            </div>

            <!-- Adicionais -->
            <div class="app-produto-comp" v-for="(adicional, indexAdicional) in adicionais"
                 :key="`adicionais-${indexAdicional}`">
              <div class="header">
                <div class="titulo">
                  <b>{{ adicional.title }}</b>
                  <span><b class="qtd-01">{{ adicional.selecionados }}</b> de <b class="qtd-02">{{ adicional.max }}</b></span>
                </div>
                <div class="right" v-if="adicional.min > 0">
                  <b-badge class="badge badge-secondary">obrigatório</b-badge>
                </div>
              </div>
              <div class="lista">
                <div class="app-produto-comp--item" :class="{checked: item.checked === true}"
                     @click="addAdicional(adicional, item)"
                     v-for="(item, indexItem) in adicional.items" :key="`adicional-item-${indexItem}`">
                  <div class="titulo">
                    {{ item.title }}
                    <small v-if="item.valor > 0">
                      R$ {{ item.valor|real }}
                    </small>
                  </div>
                  <div class="check"></div>
                </div>
              </div>
            </div>
            <!-- end:Adicionais -->

          </div>
        </div>
      </div>

      <div class="app-produto-comp--adicionar flutuante d-flex justify-content-around align-items-center p-3">

        <div class="esq">
          <div class="qtdSeletor">
            <b-icon-dash class="icone" @click="addQuantidade(-1)"></b-icon-dash>
            <span>{{ quantidade }}</span>
            <b-icon-plus class="icone" @click="addQuantidade(+1)"></b-icon-plus>
          </div>
        </div>

        <div class="dir flex-fill">
          <div class="btn btn-cor-primaria w-100 ml-2" @click="addCarrinho">
            Adicionar
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {getCarrinho, getLoja, getProduto} from "../store";

export default {
  name: "ProdutoDetalhesModal",
  data() {
    return {
      produto: null,
      loja: null,
      quantidade: 1,
      adicionais: [],
      tamanho: null,
      carrinho: null,
    };
  },
  async mounted() {
    try {

      this.loja = await getLoja(this.$route.params.loja);
      this.produto = await getProduto(this.loja.token, this.$route.params.produto);

      if (this.produto.tamanhos.length)
        this.tamanho = this.produto.tamanhos[0];

      this.adicionais = this.produto.adicionais.map(adicional => {
        return {
          title: adicional.title,
          min: adicional.min.toFloat(),
          max: adicional.max.toFloat(),
          selecionados: 0,
          items: adicional.items.map(v => {
            return {
              title: v.title,
              valor: v.valor.toFloat(),
              quantidade: 1,
              checked: false,
            };
          })
        };
      });

      this.carrinho = getCarrinho(this.loja);
      this.$forceUpdate();

    } catch (e) {
      console.log(e.message);
      if (this.loja) {
        this.$router.push('/est/' + this.loja.token);
      } else {
        this.$router.push('/');
      }
    }
  },
  computed: {
    valor() {
      let valor = 0;

      if (this.produto) {

        valor += this.produto.valor;

        if (this.tamanho)
          valor += this.tamanho.valor;

        this.adicionais.forEach(adicional => {
          adicional
              .items
              .filter(v => v.checked)
              .forEach(v => {
                valor += v.valor * v.quantidade;
              });

        });

      }
      return valor;
    },
    total() {
      return this.valor * this.quantidade;
    }
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    addQuantidade(qtde) {
      this.quantidade = Math.min(99, Math.max(1, this.quantidade + qtde));
    },
    addAdicional(adicional, item) {
      try {

        if (item.checked) {

          item.checked = false;

        } else {

          if (adicional.max > 1) {
            if (adicional.selecionados >= adicional.max)
              throw new Error(`Você já atingiu o limite de ${adicional.max} itens`)
          } else {
            adicional.items.forEach(v => v.checked = false);
          }

          item.checked = true;

        }

        // Quantidade de adicionais selecioandos
        adicional.selecionados = adicional.items.filter(v => v.checked).length;

        this.$forceUpdate();

      } catch (e) {

        alert(e.message);

      }
    },
    addCarrinho() {
      try {

        this.adicionais.forEach(adicional => {
          if (adicional.selecionados < adicional.min) {

            if (adicional.min == 1)
              throw new Error(`Selecione o(a) ${adicional.title}`);

            throw new Error(`Selecione ${adicional.min} em ${adicional.title}`);

          }
        });

        this.carrinho.produtos.push({
          title: this.produto.title,
          produto: this.produto,
          adicionais: this.adicionais,
          valor: this.valor,
          quantidade: this.quantidade,
        });

        this.$router.push('/carrinho/' + this.loja.token);

      } catch (e) {

        alert(e.message);

      }
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/app-produto-comp';
</style>