import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import {VueMaskDirective} from "v-mask";
import VueTheMask from 'vue-the-mask';
import VueRouter from "vue-router";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VuePageTransition from 'vue-page-transition';

import './string';

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueRouter);
Vue.use(VuePageTransition);

Vue.filter('real', function (value) {
    if (value)
        return value.toReal();
    return '0,00';
})

Vue.directive('mask', VueMaskDirective);

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app');

function calcWindowVh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.onresize = calcWindowVh();

window.onload = async () => {

    calcWindowVh();

    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register('/sw.js');
            console.log('Service Worker Registrado com sucessos!');
        } catch (e) {
            console.log(e.message);
        }
    }

};
