<template>
  <div @click="openLoja" class="est-item my-3">
    <div class="est-item--img">
      <img :src="loja.img" class="img-fluid"/>
    </div>
    <div class="est-item--info">
      <h2>{{ loja.nomeFantasia }}</h2>
      <ul>
        <li>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 9 8.438"
               style="margin-top: -3px;">
            <path id="star-fill"
                  d="M2.032,8.905a.3.3,0,0,1-.42-.333L2.08,5.911.1,4.024a.313.313,0,0,1,.159-.534L3.012,3.1,4.241.664a.289.289,0,0,1,.521,0L5.991,3.1l2.755.391a.313.313,0,0,1,.159.534L6.923,5.911,7.39,8.572a.3.3,0,0,1-.42.333L4.5,7.636,2.032,8.905Z"
                  transform="translate(-0.001 -0.5)" fill="#d1d80b" style="margin-top: -5px;"/>
          </svg>
          4.7
        </li>
        <li>Lanches</li>
        <li>2.1km</li>
      </ul>
      <div class="tempo-entrega">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" style="margin-top: -2px;">
          <g id="clock-history" transform="translate(0.004)">
            <path id="Caminho_3" data-name="Caminho 3"
                  d="M8.418.828Q8.21.812,8,.812V0q.24,0,.479.018l-.06.81Zm1.628.366a5.689,5.689,0,0,0-.8-.243L9.424.158a6.417,6.417,0,0,1,.915.278Zm1.113.577q-.174-.116-.357-.219l.4-.707a6.519,6.519,0,0,1,.8.531l-.5.641q-.165-.129-.34-.245Zm1.49,1.454a5.679,5.679,0,0,0-.531-.647l.588-.561a6.779,6.779,0,0,1,.607.739Zm.6,1.1q-.08-.193-.174-.38l.725-.366a6.48,6.48,0,0,1,.366.884l-.772.254q-.065-.2-.145-.392Zm.431,2.037a5.68,5.68,0,0,0-.081-.833l.8-.138a6.54,6.54,0,0,1,.094.951l-.812.02Zm-.106,1.249c.027-.138.049-.275.066-.414l.807.1a6.464,6.464,0,0,1-.187.938l-.783-.217q.056-.2.1-.407ZM12.8,9.542A5.739,5.739,0,0,0,13.2,8.8l.743.329a6.557,6.557,0,0,1-.451.843Zm-.783.979q.149-.149.284-.307l.616.531q-.156.181-.326.351l-.574-.574Z"
                  transform="translate(-1.501)" fill="#03041d" fill-rule="evenodd"/>
            <path id="Caminho_4" data-name="Caminho 4"
                  d="M6.5.812a5.687,5.687,0,1,0,4.021,9.708l.574.574A6.5,6.5,0,1,1,6.5,0Z" transform="translate(0 0)"
                  fill="#03041d" fill-rule="evenodd"/>
            <path id="Caminho_5" data-name="Caminho 5"
                  d="M7.406,3a.406.406,0,0,1,.406.406V7.639l2.639,1.508a.406.406,0,0,1-.4.705L7.2,8.227A.406.406,0,0,1,7,7.874V3.406A.406.406,0,0,1,7.406,3Z"
                  transform="translate(-1.314 -0.563)" fill="#03041d" fill-rule="evenodd"/>
          </g>
        </svg>
        45-55 min
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LojaTpl",
  props: ['loja'],
  created() {
  },
  methods: {
    openLoja() {
      this.$router.push('/est/' + this.loja.token);
    }
  }
}
</script>

<style lang="scss" scoped>
.est-item {
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.08);
  background-color: #FFF;
  border-radius: 5px;
  display: flex;
  padding: 10px;
  width: 100%;

  .est-item--img img {
    border-radius: 5px;
    height: 80px;
  }

  .est-item--info {
    padding-left: 10px;
    font-size: .9em;

    h2 {
      font-size: 1.3em;
      font-weight: 500;
      margin: 0;
    }

    ul {
      list-style: none;
      color: #515151;
      display: flex;
      padding: 0;
      margin: 0 0 10px 0;

      li {
        position: relative;
        padding-right: 13px;

        &:after {
          background-color: #515151;
          border-radius: 50px;
          position: absolute;
          content: ' ';
          height: 4px;
          width: 4px;
          right: 4px;
          top: 9px;
        }

        &:last-child {
          &:after {
            opacity: 0;
          }
        }
      }
    }

    .tempo-entrega {
      font-weight: 500;
    }
  }
}
</style>