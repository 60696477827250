export default function uniqid(a, b) {
    if (a === void 0) {
        a = "";
    }
    if (b === void 0) {
        b = false;
    }
    var c = Date.now() / 1000;
    var d = c.toString(16).split(".").join("");
    while (d.length < 14)
        d += "0";
    var e = "";
    if (b) {
        e = ".";
        e += Math.round(Math.random() * 100000000);
    }
    return a + d + e;
}