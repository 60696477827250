<template>
  <div>
    <div class="app-modal app-modal--carrinho" :class="{opened: this.opened}" v-if="loja && carrinho">

      <div class="container">
        <div class="app-modal--header">
          <div class="app-modal--action" @click="close()">
            <b-icon-chevron-down></b-icon-chevron-down>
          </div>
          <h2>Carrinho</h2>
        </div>
        <div class="app-modal--conteudo">

          <div class="tipo-de-retirada">
            <div class="btn btn-sm btn-dark mr-2"
                 @click="carrinho.tipo = v.value"
                 :class="v.value === carrinho.tipo ? 'btn-cor-primaria' : 'text-white btn-outline-secondary'"
                 v-for="v in tipos"
                 :key="`tipo-${v.value}`">
              {{ v.title }}
            </div>
            <hr/>
          </div>

          <div class="mb-2" v-if="carrinho.tipo === 'entrega'">
            <router-link :to="`endereco/${loja.token}`" class="end-info">
              <div class="row align-items-center">
                <div class="col">

                  <ul class="flex-fill" v-if="carrinho.endereco">

                    <li>
                      Entregar em: <b>{{ carrinho.endereco.title }}</b>
                    </li>

                    <li>
                      <b>
                        {{ carrinho.endereco.logradouro }},
                        {{ carrinho.endereco.numero }},
                        {{ carrinho.endereco.bairro.title }}
                      </b>
                    </li>

                    <li v-if="carrinho.endereco.complemento">{{ carrinho.endereco.complemento }}</li>

                  </ul>

                  <ul class="flex-fill" v-else>
                    Entrega em: <b>Informe seu endereço</b>
                  </ul>

                </div>
                <div class="col-auto">

                  <div class="btn-endereco text-right">
                    <b-icon-chevron-right></b-icon-chevron-right>
                  </div>

                </div>
              </div>

            </router-link>
          </div>

          <div class="bar-tempo-de-entrega btn btn-light btn-sm w-100 text-left mb-3">44 - 55 min</div>

          <div class="pedido-res-txt">

            <h4>{{ loja.nomeFantasia }}</h4>

            <div v-for="(v, index) in carrinho.produtos" :key="`pedido-item-${index}`">

              <hr class="hr-produtos" v-if="index">

              <ul class="pedido-res-txt--item list-unstyled">

                <li>{{ v.quantidade }}x {{ v.title }}</li>

                <li v-for="(adicional, indexAdicional) in v.adicionais.filter(v => v.selecionados > 0)"
                    :key="`adicional-${index}-${indexAdicional}`">
                  <div class="badge badge-light">{{ adicional.title }}</div>
                  {{ adicional.items.filter(v => v.checked).map(v => v.title).join(', ') }}
                </li>

              </ul>

              <div class="pedido-res-txt--preco">
                <div>R$ {{ (v.valor * v.quantidade)|real }}</div>
              </div>

            </div>

            <div v-if="!carrinho.produtos.length">
              <div class="alert alert-warning">
                <b-icon-info></b-icon-info>
                Seu pedido está vazio
              </div>
            </div>

          </div>

          <div class="pedido-add-itens my-2">
            <router-link :to="`/est/${loja.token}`">
              Adicionar mais itens
            </router-link>
          </div>

          <div class="pedido-res-total">
            <div class="row text-muted">
              <div class="col-6">Subtotal</div>
              <div class="col-6 text-right">R$ {{ carrinho.subtotal|real }}</div>
            </div>
            <div class="row">
              <div class="col-6 text-muted">Cupom</div>
              <div class="col-6 text-right text-success">- R$ {{ carrinho.desconto|real }}</div>
            </div>
            <div class="row">
              <div class="col-6 text-muted">Taxa de entrega</div>
              <div class="col-6 text-right text-success">
                <div v-if="carrinho.tipo === 'entrega' && carrinho.taxaEntrega > 0">
                  R$ {{ carrinho.taxaEntrega | real }}
                </div>
                <div v-else>
                  Grátis
                </div>
              </div>
            </div>
            <div class="row font-weight-bold">
              <div class="col-6">Total</div>
              <div class="col-6 text-right">R$ {{ carrinho.total|real }}</div>
            </div>
          </div>

          <div class="separador my-2"></div>

          <router-link :to="`pagamento/${loja.token}`" class="pedido-fin-01 pedido-fin-selecionar-forma">
            <div class="d-flex align-items-center">
              <div class="lado-esquerdo flex-fill d-flex">
                <div class="text-success pedido-fin-01--icone">
                  <b-icon-cash></b-icon-cash>
                </div>
                <div class="txt px-3">
                  <div class="text-muted">Pagamento via</div>
                  <div class="pedido-fin-01--forma-de-pagamento font-weight-bold">Selecionar Forma de Pagamento</div>
                </div>
              </div>
              <div class="lado-direito text-right text-cor-primaria">
                <b-icon-chevron-right></b-icon-chevron-right>
              </div>
            </div>
          </router-link>
          <div class="pedido-fin-01 pedido-fin-cupom">
            <hr/>
            <div class="d-flex align-items-center">
              <div class="lado-esquerdo flex-fill d-flex">
                <div class="txt">
                  <div class="pedido-fin-01--forma-de-pagamento">Cupom</div>
                </div>
              </div>
              <div class="lado-direito text-right text-cor-primaria">
                <b-icon-chevron-right></b-icon-chevron-right>
              </div>
            </div>
          </div>
          <router-link to="/documento" class="pedido-fin-01 pedido-cpf-cnpj">
            <hr/>
            <div class="d-flex align-items-center">
              <div class="lado-esquerdo flex-fill d-flex">
                <div class="txt">
                  <div class="pedido-fin-01--forma-de-pagamento">CPF/CNPJ na nota</div>
                </div>
              </div>
              <div class="lado-direito text-right text-cor-primaria">
                <b-icon-chevron-right></b-icon-chevron-right>
              </div>
            </div>
            <hr/>
          </router-link>
        </div>
      </div>
    </div>
    <div class="pedido-realizar-01 flutuante">
      <div class="btn btn-cor-primaria w-100">Fazer pedido</div>
    </div>
  </div>
</template>

<script>
import {getCarrinho, getLoja} from "../store";

export default {
  name: "CarrinhoPage",
  data() {
    return {
      opened: true,
      loja: null,
      carrinho: null,
      tipos: [
        {value: 'entrega', title: 'Entrega'},
        {value: 'balcao', title: 'Retirada'},
      ]
    };
  },
  async created() {
    this.loja = await getLoja(this.$route.params.token);
    this.carrinho = getCarrinho(this.loja);
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>


.pedido-fin-01 {
  .pedido-fin-01--icone {
    font-size: 2.1em;
  }
}

.pedido-res-txt {
  .pedido-res-txt--item {
    color: #515151;
  }

  .pedido-res-txt--preco {
    font-weight: 600;
    color: #515151;
  }
}

.pedido-add-itens {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  text-align: center;
  padding: 10px 0px;
  font-weight: 500;
  color: var(--cor-primaria-normal);
}

.pedido-realizar-01 {
  background-color: #FFF;
  border-top: 1px solid #eee;
  padding: 15px 10px;
}

</style>