import Vuex from 'vuex';
import Vue from 'vue';
import apiHttp from "./apiHttp";
import uniqid from "../../pdv/src/helpers/uniqid";

Vue.use(Vuex);

let state = {
    carrinho: {},
    lojas: [],
    produtos: [],
    categorias: [],
};

const store = new Vuex.Store({
    state,
    mutations: {
        addLoja(state, loja) {
            state.lojas.push(loja);
        },
        addCategorias(state, categorias) {
            categorias.forEach((categoria) => {

                const existe = state.categorias.find(v => v.id === categoria.id);

                if (existe) {
                    Object.assign(existe, categoria);
                } else {
                    state.categorias.push(categoria);
                }

            });
        },
        addProdutos(state, produtos) {
            produtos.forEach((produto) => {

                const existe = state.produtos.find(v => v.token === produto.token);

                if (existe) {
                    Object.assign(existe, produto);
                } else {
                    state.produtos.push(produto);
                }

            });
        },
    },
});

export async function getProduto(lojaToken, produtoToken) {

    const loja = await getLoja(lojaToken);

    let produto = state.produtos.find(v => v.token === produtoToken && v.loja === loja.id);

    if (!produto) {

        let {produtos} = await getProdutos(loja);

        produto = produtos.find(v => v.token === produtoToken && v.loja === loja.id);

        if (!produto)
            throw new Error('Produto não encontrado');

    }

    return produto;

}

export async function getLoja(token) {

    const loja = state.lojas.find(v => v.token === token);

    if (loja) {

        return loja;

    } else {

        const {data} = await apiHttp.get('/loja', {
            headers: {
                'loja-token': token,
            },
        });

        if (data.result === 1) {

            store.commit('addLoja', data.loja);

            return data.loja;

        } else {

            throw new Error(data.message);

        }

    }
}

export async function getProdutos(loja) {

    let categorias = [];
    let produtos = state.produtos.filter(v => v.loja === loja.id);

    if (produtos.length) {

        categorias = state.categorias.filter(v => v.loja === loja.id);

    } else if (!produtos.length) {

        const {data} = await apiHttp.get('/loja/produtos', {
            headers: {
                'loja-token': loja.token,
            },
        });

        if (data.result === 1) {

            store.commit('addCategorias', data.categorias.map(v => {
                v.loja = loja.id;
                return v;
            }));

            store.commit('addProdutos', data.produtos);

            categorias = state.categorias.filter(v => v.loja === loja.id);
            produtos = state.produtos.filter(v => v.loja === loja.id);

        }

    }

    return {
        produtos,
        categorias,
    };

}

export function getCarrinho(loja) {
    if (typeof state.carrinho[loja.token] === 'object') {
        return state.carrinho[loja.token];
    } else {
        return state.carrinho[loja.token] = {
            token: uniqid(),
            loja: loja,
            tipo: 'entrega',
            produtos: [],
            pagamentos: [],
            endereco: null,
            cpf: null,
            cnpj: null,
            get total() {
                let total = this.subtotal;
                total += this.acrescimo.toFloat();
                total -= this.desconto.toFloat();
                if(this.tipo === 'entrega')
                total += this.taxaEntrega.toFloat();
                return total;
            },
            get subtotal() {
                let total = 0;
                this.produtos.forEach(v => total += v.valor.toFloat() * v.quantidade.toFloat());
                return total;
            },
            desconto: 0,
            taxaEntrega: 0,
            acrescimo: 0,
        };
    }
}

export default store;