<template>
  <div>
    <div style="min-height: calc(100vh - 75px);">
      <div class="app-modal app-modal--carrinho">
        <div class="container">
          <div class="app-modal--header">
            <div class="app-modal--action" @click="close">
              <b-icon-chevron-left></b-icon-chevron-left>
            </div>
            <h2>Pedidos</h2>
          </div>
          <div class="app-modal--conteudo">
            <div class="separador-data mb-2">15/12</div>
            <div class="pedido-mini">
              <div class="pedido-mini--est">
                <img src="@/assets/est.jpg" class="img-fluid" />
                <div class="pedido-mini--est-info">
                  <h4>Bliss Burger</h4>
                  <small>Pedido concluído #8930</small>
                </div>
              </div>
                <hr/>
                <div class="pedido-mini--est-itens">
                  <div class="item">
                    <div class="qtd">1</div>
                    <div class="nome flex-fill">Monte sua massa</div>
                  </div>
                </div>
                <router-link to="/avaliacao" class="bar-avalie btn btn-light btn-sm w-100 text-left my-2 d-flex">
                  <div class="txt flex-fill">Avalie o seu pedido</div>
                  <div class="icone">
                    <b-icon-star-fill></b-icon-star-fill>
                    <b-icon-star-fill></b-icon-star-fill>
                    <b-icon-star-fill></b-icon-star-fill>
                    <b-icon-star-fill></b-icon-star-fill>
                    <b-icon-star-fill></b-icon-star-fill>
                  </div>
                </router-link>
                <div class="pedido-mini--est-acoes text-center">
                  <div class="btn btn-secondary-outline text-cor-primaria">Ajuda</div>
                  <router-link to="/pedidoDetalhes" class="btn btn-secondary-outline text-cor-primaria">Detalhes</router-link>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../component/Footer";

export default {
  name: "PedidosPage",
  components: {Footer},
  methods: {
    close(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>