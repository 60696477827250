<template>
  <div class="footer">
    <router-link to="/" class="footer--item">
      <b-icon-house></b-icon-house>
      <span>Início</span>
    </router-link>
    <router-link to="/favoritos" class="footer--item">
      <b-icon-heart></b-icon-heart>
      <span>Favoritos</span>
    </router-link>
    <router-link :to="`/carrinho${loja ? `/${loja.token}` : ''}`" class="footer--item">
      <div class="footer--item-icone">
        <b-icon-cart></b-icon-cart>
        <div class="footer--item-numero">3</div>
      </div>
      <span>Carrinho</span>
    </router-link>
    <router-link to="/pedidos" class="footer--item">
      <b-icon-tags></b-icon-tags>
      <span>Pedidos</span>
    </router-link>
    <router-link to="/perfil" class="footer--item">
      <b-icon-person></b-icon-person>
      <span>Perfil</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "Footer",
  props:['loja'],
  methods: {
    openCarrinho() {
      this.$root.$emit('openCart', true);
    }
  }
}
</script>

<style lang="scss" scoped>


.footer {
  font-family: 'Poppins', sans-serif;
  border-top: 1px solid #EFEFEF;
  justify-content: center;
  background-color: #FFF;
  align-items: center;
  padding: 7px 30px;
  position: sticky;
  z-index: 99;
  display: flex;
  height: 75px;
  width: 100%;
  bottom: 0;
  left: 0;

  .footer--item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 10px;
    font-size: .8em;
    flex: 1 1 auto;

    a {
      text-decoration: none;
      text-align: center;
      display: flex;
      flex-direction: column;
    }

    .footer--item-icone {
      position: relative;

      .footer--item-numero {
        background-color: var(--cor-primaria-normal) !important;
        color: var(--cor-primaria-link) !important;
        border-radius: 50px;
        text-align: center;
        position: absolute;
        right: -10px;
        height: 18px;
        width: 18px;
        top: 0;
      }
    }

    svg {
      font-size: 2em;
      fill: #BEBEC7;
    }

    span {
      padding: 5px 0px;
      color: #515151;
    }

    .router-link-exact-active {
      svg {
        fill: #03041D;
      }

      span {
        color: #000;
      }
    }
  }
}
</style>