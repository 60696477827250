<template>
  <div style="min-height: calc(100vh - 75px);">
    <div class="app-modal app-modal--produtoDetalhes">
      <div class="container">

        <div class="app-modal--header">
          <div class="app-modal--action" @click="$router.go(-1)">
            <b-icon-chevron-left></b-icon-chevron-left>
          </div>
          <h2>Forma de Pagamento</h2>
        </div>

        <div class="app-modal--conteudo">
          <div class="lista-formas-pagamento pague-online">

            <div class="item">
              <div class="txt d-flex w-100 justify-content-between">
                <div class="left"><b>Pague Online</b></div>
                <div class="right"></div>
              </div>
              <hr/>
            </div>

            <router-link to="/cartao" class="item">
              <div class="txt d-flex w-100 justify-content-between">
                <div class="left">Cartão de Crédito/Débito</div>
                <div class="right"><b-icon-plus-circle></b-icon-plus-circle></div>
              </div>
              <hr/>
            </router-link>

            <div class="item">
              <div class="txt d-flex w-100 justify-content-between">
                <div class="left">Picpay</div>
                <div class="right"><b-icon-plus-circle></b-icon-plus-circle></div>
              </div>
              <hr/>
            </div>

          </div>

          <div class="lista-formas-pagamento pague-na-entrega">

            <div class="item">
              <div class="txt d-flex w-100 justify-content-between">
                <div class="left"><b>Pague na entrega</b></div>
                <div class="right"></div>
              </div>
              <hr/>
            </div>

            <div class="item">
              <div class="txt d-flex w-100 justify-content-between">
                <div class="left">Cartão de Crédito/Débito</div>
                <div class="right"><b-icon-plus-circle></b-icon-plus-circle></div>
              </div>
              <hr/>
            </div>

            <div class="item">
              <div class="txt d-flex w-100 justify-content-between">
                <div class="left">Dinheiro</div>
                <div class="right"><b-icon-plus-circle></b-icon-plus-circle></div>
              </div>
              <hr/>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "FormaPagamentoModal"
}
</script>

<style lang="scss" scoped>

.lista-formas-pagamento {
  .item {
    color: #515151;
    b {color: #333;font-weight: 500;}
    svg {
      fill: var(--cor-primaria-normal);
      color: var(--cor-primaria-normal);
    }
  }
}

</style>