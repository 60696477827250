<template>
  <div>
    <div class="header-hp my-2">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <h3>Incrível Foods</h3>
          </div>
          <div class="col-md-12">
            <input type="text" class="form-control header-hp--search-input" name="search" placeholder="Pesquisar..."/>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="categorias-wrapper position-relative d-block w-100">
        <div class="categorias scroll-horizontal">
          <div class="categorias-lista d-flex">
            <div class="categoria-item mr-2">Açaí</div>
            <div class="categoria-item mr-2">Lanches</div>
            <div class="categoria-item mr-2">Saudável</div>
            <div class="categoria-item mr-2">Japonesa</div>
            <div class="categoria-item mr-2">Doces & Bolos</div>
            <div class="categoria-item mr-2">Carnes</div>
            <div class="categoria-item mr-2">Carnes5</div>
            <div class="categoria-item mr-2">Carnes111</div>
            <div class="categoria-item mr-2">Carnes23</div>
            <div class="categoria-item mr-2">Carnesas</div>
            <div class="categoria-item mr-2">Carnes</div>
            <div class="categoria-item mr-2">Italiana</div>
          </div>
        </div>
      </div>
      <div class="slide-hp mt-2">
        <div class="slide-hp--item"></div>
      </div>
      <div class="estabelecimentos-lista">
        <div v-for="(v, index) in estabelecimentos" :key="`estabelecimento-${index}`">
          <LojaTpl :loja="v"/>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from "../component/Footer";
import apiHttp from "../apiHttp";
import LojaTpl from "../tpl/LojaTpl";

export default {
  name: "HomePage",
  components: {LojaTpl, Footer},
  data() {
    return {
      estabelecimentos: [],
    };
  },
  async created() {
    const {data} = await apiHttp.get('/lojas');
    if (data.result === 1)
      this.estabelecimentos = data.lojas;
  }
}
</script>

<style scoped lang="scss">
.header-hp {
  .header-hp--search-input {
    border-color: rgba(190, 190, 199, 0);
    background-color: #BEBEC7;
    text-align: center;
    position: relative;
    color: #FFF;

    &::placeholder {
      color: #FFF;
    }

    &:focus {
      box-shadow: 0 0 5px 0 rgba(123, 123, 123, 0.15);
    }

    &:before {
      position: absolute;
      padding-left: 50px;
      content: url("data:image/svg+xml,%3Csvg id='search' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='Caminho_1' data-name='Caminho 1' d='M10.442,10.442a1,1,0,0,1,1.415,0l3.85,3.85a1,1,0,0,1-1.414,1.415l-3.85-3.85a1,1,0,0,1,0-1.415Z' fill='%23515151' fill-rule='evenodd'/%3E%3Cpath id='Caminho_2' data-name='Caminho 2' d='M6.5,12A5.5,5.5,0,1,0,1,6.5,5.5,5.5,0,0,0,6.5,12ZM13,6.5A6.5,6.5,0,1,1,6.5,0,6.5,6.5,0,0,1,13,6.5Z' fill='%23515151' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
      height: 16px;
      width: 16px;
      left: 0;
      top: 0;
    }
  }
}

.slide-hp {
  background-color: #eee;
  border-radius: 5px;
  height: 86px;
  width: 100%;
}

.scroll-horizontal {
  position: absolute;
  overflow: hidden;
  height: 30px;
  width: 100%;
}

.categorias-wrapper {
  height: 30px;
}

.categorias-lista {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 20px;
  box-sizing: content-box;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 95px;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
}

</style>