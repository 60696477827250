<template>
  <div>
    <div style="min-height: calc(100vh - 75px);">
      <div class="app-modal app-modal--perfil-page">
        <div class="container">
          <div class="app-modal--header">
            <div class="app-modal--action" @click="close()">
              <b-icon-chevron-left></b-icon-chevron-left>
            </div>
            <h2>Perfil</h2>
          </div>
          <div class="app-modal--conteudo">
            <div class="app-user-info">
              <img src="@/assets/est.jpg" alt="">
              <h3>Hebert Tutu</h3>
              <div class="local">
                <b-icon-geo-alt></b-icon-geo-alt> Caruaru/PE
              </div>
            </div>
            <hr/>
            <div class="menu-vertical">
              <router-link to="/endForm"><b-icon-geo-alt class="icone"></b-icon-geo-alt> Endereço de Entrega</router-link>
              <router-link to="/formasPagamento"><b-icon-credit-card class="icone"></b-icon-credit-card> Formas de Pagamento</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/component/Footer";
export default {
name: "PerfilPage",
  components: {Footer}
}
</script>

<style scoped lang="scss">
  .app-user-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      border-radius: 50%;
      height: 110px;
      width: 110px;
    }
    h3 {
      margin: 8px 0px;
      font-weight: 500;
    }
    .local {
      color: #636363;
    }
  }

  .menu-vertical {
    display: flex;
    align-items: center;
    flex-flow: column;

    font-size: 1em;
    padding: 0;
    margin: 0;

    a {
      display: flex;
      align-items: center;

      border-bottom: 1px solid #eee;
      padding: 12px 0px;
      color: #656565;
      width: 100%;

      .icone {
        margin-right: 15px;
        font-size: 1.5em;
        color: #515151;
      }
    }
  }

</style>