<template>
  <div>
    <div class="app-modal app-modal--carrinho" :class="{opened: this.opened}">
      <div class="container">
        <div class="app-modal--header">
          <div class="app-modal--action" @click="close()">
            <b-icon-chevron-down></b-icon-chevron-down>
          </div>
          <h2>Detalhes do pedido</h2>
        </div>
        <div class="app-modal--conteudo">
          <div class="resumo-info">
            <div class="restaurante-mini">
              <img src="@/assets/est.jpg" alt="" class="img-fluid">
              <h4>Bliss Burger</h4>
            </div>
            <div class="numero">Pedido #39284</div>
          </div>
          <hr/>
          <div class="pedidoDetalhes--status">
            <b-icon-check-circle-fill class="icone text-cor-primaria"></b-icon-check-circle-fill>
            <div class="txt">
              <b>Confirmado!</b>
              <p>O restaurante está preparando o pedido.</p>
            </div>
          </div>
          <hr/>
          <div class="pedido-mini--est-itens">
            <div class="item">
              <div class="qtd">1</div>
              <div class="nome flex-fill">Monte sua massa</div>
              <div class="preco pull-right">R$ 24,99</div>
            </div>
          </div>
          <hr/>
          <div class="pedido-res-total">
            <div class="row text-muted">
              <div class="col-6">Subtotal</div>
              <div class="col-6 text-right">R$ 24,99</div>
            </div>
            <div class="row">
              <div class="col-6 text-muted">Cupom</div>
              <div class="col-6 text-right text-success">- R$ 5,01</div>
            </div>
            <div class="row">
              <div class="col-6 text-muted">Taxa de entrega</div>
              <div class="col-6 text-right text-success">Grátis</div>
            </div>
            <div class="row font-weight-bold">
              <div class="col-6">Total</div>
              <div class="col-6 text-right">R$ 20,00</div>
            </div>
          </div>
          <hr/>
          <div class="pedido-end-entrega">
            <b class="font-weight-500">Endereço de entrega</b>
            <p class="rua mb-0 text-cor-cinza">Rua Antenor Navarro, 590, Petrópolis, AP 102</p>
            <p class="referencia mb-0 text-cor-cinza">Na rua do Samu/Celpe</p>
          </div>
          <hr/>
          <div class="pedido-pagamento">
            <b class="font-weight-500">Pagamento</b>
            <p class="rua mb-0 text-cor-cinza">Cartão de Crédito/Débito - Pague na entrega</p>
          </div>
          <hr/>
          <router-link to="/avaliacao" class="bar-avalie btn btn-light btn-sm w-100 text-left my-2 d-flex">
            <div class="txt flex-fill">Avalie o seu pedido</div>
            <div class="icone">
              <b-icon-star-fill></b-icon-star-fill>
              <b-icon-star-fill></b-icon-star-fill>
              <b-icon-star-fill></b-icon-star-fill>
              <b-icon-star-fill></b-icon-star-fill>
              <b-icon-star-fill></b-icon-star-fill>
            </div>
          </router-link>
          <router-link to="/" class="barra-de-ajuda">
            <div class="txt">
              <b-icon-question-square-fill class="icone"></b-icon-question-square-fill>
              Precisa de ajuda?
            </div>
            <div class="acao">
              Fale conosco!
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PedidoDetalhesModal",
  methods: {
    close() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss">

.restaurante-mini {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    font-size: 1em;
    margin: 0;
  }

  img {
    border-radius: 50px;
    margin-right: 5px;
    height: 40px;
    width: 40px;
  }
}

.resumo-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .numero {
    font-size: .9em;
  }
}

</style>