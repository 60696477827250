import VueRouter from "vue-router";
import HomePage from "@/page/HomePage";
import CarrinhoPage from "./page/CarrinhoPage";
import PedidosPage from "./page/PedidosPage";
import EstabelecimentoPage from "@/page/EstabelecimentoPage";
import ProdutoDetalhes from "@/modal/ProdutoDetalhesModal";
import EnderecoFormModal from "@/modal/EnderecoFormModal";
import PerfilPage from "@/page/PerfilPage";
import FormaPagamentoModal from "@/modal/FormaPagamentoModal";
import CartaoModal from "@/modal/CartaoModal";
import AvaliacaoPedidoPage from "@/page/AvaliacaoPedidoPage";
import PedidoDetalhesModal from "@/modal/PedidoDetalhesModal";

const router = new VueRouter({
    mode: 'hash',
    props: true,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    },
    routes: [
        {
            name: 'HomePage',
            path: '/',
            component: HomePage
        },
        {
            name: 'CarrinhoPage',
            path: '/carrinho/:token',
            component: CarrinhoPage,
            meta: {transition: 'fade-in-up'}
        },
        {
            name: 'PedidosPAge',
            path: '/pedidos',
            component: PedidosPage,
        },
        {
            name: 'EstabelecimentoPage',
            path: '/est/:token',
            component: EstabelecimentoPage,
        },
        {
            name: 'ProdutoDetalhesPage',
            path: '/produto/:loja/:produto',
            component: ProdutoDetalhes,
        },
        {
            name: 'EnderecoFormModal',
            path: '/carrinho/endereco/:token',
            component: EnderecoFormModal,
        },
        {
            name: 'FormaPagamentoModal',
            path: '/carrinho/pagamento/:token',
            component: FormaPagamentoModal,
        },
        {
            name: 'CartaoModal',
            path: '/cartao',
            component: CartaoModal,
        },
        {
            name: 'pedidoDetalhes',
            path: '/pedidoDetalhes',
            component: PedidoDetalhesModal,
        },
        {
            name: 'AvaliacaoPedido',
            path: '/avaliacao',
            component: AvaliacaoPedidoPage,
        },
        {
            name: 'Perfil',
            path: '/perfil',
            component: PerfilPage,
        }
    ],
});

export default router;