<template>
  <div style="min-height: calc(100vh - 75px);">
    <div class="app-modal app-modal--produtoDetalhes">
      <div class="container">
        <div class="app-modal--header">
          <div class="app-modal--action" @click="$router.go(-1)">
            <b-icon-chevron-left></b-icon-chevron-left>
          </div>
          <h2>Cartão de Crédito/Débito</h2>
        </div>
        <div class="app-modal--conteudo">
          <div class="row formulario mb-3">
            <div class="col-12">
              <input type="text" name="numero" placeholder="Nome" class="form-control mb-2">
            </div>
            <div class="col-6">
              <input type="text" name="validade" placeholder="Validade" class="form-control mb-2">
            </div>
            <div class="col-6">
              <input type="text" name="cvv" placeholder="CVV" class="form-control mb-2">
            </div>
            <div class="col-12">
              <input type="text" name="titular" placeholder="Nome do titular" class="form-control mb-2">
            </div>
            <div class="col-12">
              <input type="text" name="documento" placeholder="CPF/CNPJ" class="form-control mb-2">
            </div>
            <div class="col-12">
              <div class="btn btn-cor-primaria w-100">Salvar</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "CartaoModal"
}
</script>

<style scoped lang="scss">

</style>