<template>
  <div style="min-height: calc(100vh - 75px);">
    <div class="app-modal app-modal--produtoDetalhes">
      <div class="container">
        <div class="app-modal--header">
          <div class="app-modal--action" @click="$router.go(-1)">
            <b-icon-chevron-left></b-icon-chevron-left>
          </div>
          <h2>Endereço de Entrega</h2>
        </div>
        <div class="app-modal--conteudo">

          <form @submit.prevent="finalizar" class="row formulario mb-3">

            <div class="col-12">
              <input type="text" v-model="endereco.title" placeholder="Nome" required autofocus
                     class="form-control mb-2">
            </div>

            <div class="col-12">
              <input type="text" v-model="endereco.cep" @keyup="checkCep" v-mask="'#####-###'" placeholder="CEP"
                     class="form-control mb-2 input-cep" required :readonly="buscando">
            </div>

            <div class="col-8">
              <input type="text" :value="endereco.logradouro" placeholder="Logradouro" class="form-control mb-2"
                     readonly required>
            </div>

            <div class="col-4">
              <input type="text" v-model="endereco.numero" placeholder="Número" class="form-control input-numero mb-2">
            </div>

            <div class="col-12">
              <input type="text" :value="endereco.bairro.title" placeholder="Bairro" class="form-control mb-2" readonly
                     required>
            </div>

            <div class="col-12">
              <input type="text" v-model="endereco.complemento" placeholder="Complemento" class="form-control mb-2"
                     required>
            </div>

            <div class="col-12">
              <input :value="`${endereco.cidade.id ? endereco.cidade.title + '/' + endereco.estado.uf : ''}`"
                     class="form-control mb-2" readonly placeholder="Cidade/UF" required/>
            </div>

            <div class="col-12">
              <input type="text" v-model="endereco.pontoReferencia" placeholder="Ponto de Referência"
                     class="form-control mb-2">
            </div>

            <div class="col-12">
              <button type="submit" class="btn btn-cor-primaria btn-block">
                Salvar
              </button>
            </div>

          </form>

          <div class="lista-enderecos" v-for="(v, index) in enderecos" :key="`endereco-${index}`">
            <div class="end-info" @click="endereco = v">
              <div class="row align-items-center">
                <div class="col">
                  <ul class="flex-fill">
                    <li>Entregar em: <b>{{ v.title }}</b></li>
                    <li><b>{{ v.logradouro + ', ' + v.numero + ' - ' + v.bairro.title }}</b></li>
                    <li v-if="v.complemento">{{ v.complemento }}</li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div class="btn-endereco text-right">
                    <b-icon-pencil-square></b-icon-pencil-square>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiHttp from "../apiHttp";
import {getCarrinho, getLoja} from "../store";

export default {
  name: "EnderecoFormModal",
  data() {
    return {
      carrinho: null,
      loja: null,
      buscando: false,
      cepOk: null,
      endereco: {
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        pontoReferencia: null,
        bairro: {
          id: 0,
          title: null,
          valor: 0,
        },
        cidade: {
          id: 0,
          title: null,
        },
        estado: {
          id: 0,
          title: null,
          uf: null,
        }
      },
      enderecos: [],
    };
  },
  async created() {
    this.loja = await getLoja(this.$route.params.token);
    this.carrinho = getCarrinho(this.loja);
    if (this.carrinho.endereco) {
      this.enderecos = [this.carrinho.endereco];
      this.endereco = this.carrinho.endereco;
    }
  },
  methods: {
    async checkCep() {
      try {

        if (!this.buscando) {

          let cep = this.endereco.cep;
          let teste = /^[0-9]{5}-[0-9]{3}$/;

          if (teste.test(cep) && cep !== this.cepOk) {

            this.buscando = true;

            const {data} = await apiHttp.get(`/endereco?cep=${cep}`, {
              headers: {
                'loja-token': this.loja.token,
              },
            });

            if (data.result !== 1)
              throw new Error(data.message);

            Object.assign(this.endereco, data.endereco);

            this.cepOk = cep;

            document.getElementsByClassName('input-numero')[0].focus();

          }

        }

      } catch (e) {

        alert(e.message);

        this.endereco.cep = null;

        document.getElementsByClassName('input-cep')[0].focus();

      } finally {

        this.buscando = false;

      }
    },
    async finalizar() {
      try {

        console.log(this.endereco);

        if (!this.endereco.numero || this.endereco.numero === ''){
          this.endereco.numero = 'S/N';
        }

        this.carrinho.endereco = this.endereco;
        this.carrinho.taxaEntrega = this.endereco.bairro.valor;

        this.$router.go(-1);

      } catch (e) {

        alert(e.message);

      }
    }
  }
}
</script>

<style scoped>

</style>