<template>
  <div id="app">
    <div class="page-content">
      <vue-page-transition name="fade-in-left">
        <router-view key="rotas"/>
      </vue-page-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">

:root {
  --vh: 1vh;
  --cor-primaria-normal: #45b34a;
  --cor-primaria-light: #272727;
  --cor-primaria-lighten: #17152D;
  --cor-primaria-color: #FFF;
  --cor-primaria-link: #FFF;
  --cor-secundaria: #ff8a29;
  --cor-secundaria-light: #ffca00;
  --cor-secundaria-lighten: #ffca00;
  --cor-secundaria-color: #303030;
  --cor-secundaria-link: #202020;
}

@import 'assets/scss/tema';
@import 'assets/scss/misc';
@import 'assets/scss/app-modal';
@import 'assets/scss/pedido-mini';

.app-page-full {
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
}

.categoria-item {
  background-color: var(--cor-primaria-normal) !important;
  color: var(--cor-primaria-link) !important;
  font-family: Poppins, sans-serif;
  border-radius: 50px;
  padding: 5px 15px;
  font-weight: 400;
  font-size: .9em;
  height: 30px;
}

</style>
